// src/components/ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, ...rest }) => {
  const authData = localStorage.getItem('authData');

  // If there is no authentication data, redirect to the login page
  if (!authData) {
    return <Navigate to="/login" />;
  }

  // If authentication exists, render the provided elements
  return element;
};

export default ProtectedRoute;
