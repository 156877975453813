import React, { useState, useEffect } from "react";
import "./ScanResults.css";

function extractQueryParams(url) {
  const params = new URLSearchParams(new URL(url).search);
  return {
    environment: params.get("environment"),
    model: params.get("model"),
    modelVersion: params.get("modelVersion"),
    saveas: params.get("saveas"),
  };
}

function ScanResults({ scannedResults }) {
  const [isDetailVisible, setIsDetailVisible] = useState(true);
  const [isDurationVisible, setIsDurationVisible] = useState(true);
  const [isRepeatedRulesVisible, setIsRepeatedRulesVisible] = useState(true);
  const [isBaseTraceVisible, setIsBaseTraceVisible] = useState(true);
  const [firstCalledRule, setFirstCalledRule] = useState(null);
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    if (
      scannedResults.repeatedRules &&
      scannedResults.repeatedRules.length > 0
    ) {
      setFirstCalledRule(scannedResults.repeatedRules[0]);
    }

    // Extract query parameters from the URL
    if (scannedResults.url) {
      setQueryParams(extractQueryParams(scannedResults.url));
    }
  }, [scannedResults]);

  const handleDetailCheckboxChange = () => {
    setIsDetailVisible(!isDetailVisible);
  };

  const handleDurationCheckboxChange = () => {
    setIsDurationVisible(!isDurationVisible);
  };

  const handleRepeatedRulesCheckboxChange = () => {
    setIsRepeatedRulesVisible(!isRepeatedRulesVisible);
  };

  const handleBaseTraceCheckboxChange = () => {
    setIsBaseTraceVisible(!isBaseTraceVisible);
  };

  return (
    <div className="scan-results">
      <div className="scan-results-section">
        {/* Display extracted query parameters */}
        <div className="scan-results-header">
          <h2>Detail</h2>
          <input
            type="checkbox"
            checked={isDetailVisible}
            onChange={handleDetailCheckboxChange}
            className="toggle-visibility-checkbox"
          />
        </div>
        <div
          className={`scan-results-content ${
            isDetailVisible ? "visible" : "hidden"
          }`}
        >
          {queryParams.environment && (
            <p>
              <strong>Environment:</strong> {queryParams.environment}
            </p>
          )}
          {queryParams.model && (
            <p>
              <strong>Model:</strong> {queryParams.model}
            </p>
          )}
          {queryParams.modelVersion && (
            <p>
              <strong>Model Version:</strong> {queryParams.modelVersion}
            </p>
          )}
          {queryParams.saveas && (
            <p>
              <strong>Save As:</strong> {queryParams.saveas}
            </p>
          )}
        </div>
      </div>

      <div className="scan-results-section">
        <div className="scan-results-header">
          <h2>Total Duration</h2>
          <input
            type="checkbox"
            checked={isDurationVisible}
            onChange={handleDurationCheckboxChange}
            className="toggle-visibility-checkbox"
          />
        </div>
        <div
          className={`scan-results-content ${
            isDurationVisible ? "visible" : "hidden"
          }`}
        >
          <p>
            <strong>Milliseconds:</strong> {scannedResults.milliseconds} ms
          </p>
          <p>
            <strong>Seconds:</strong> {scannedResults.seconds} s
          </p>
          <p>
            <strong>Minutes:</strong> {scannedResults.minutes} min
          </p>
        </div>
      </div>

      <div className="scan-results-section">
        <div className="scan-results-header">
          <h2>Repeated Rules</h2>
          <input
            type="checkbox"
            checked={isRepeatedRulesVisible}
            onChange={handleRepeatedRulesCheckboxChange}
            className="toggle-visibility-checkbox"
          />
        </div>
        <div className="info-container">
          <div className="info-text">
            *Green for first called rule, red for rule that called more than 3
            times*
          </div>
        </div>

        <div
          className={`scan-results-content ${
            isRepeatedRulesVisible ? "visible" : "hidden"
          }`}
        >
          {firstCalledRule && (
            <p className="first-called">
              <strong>First Called Rule:</strong> {firstCalledRule.rule},{" "}
              <strong>Count:</strong> {firstCalledRule.count},{" "}
              <strong>Total Duration:</strong> {firstCalledRule.totalDuration}{" "}
              ms
            </p>
          )}
          {scannedResults.repeatedRules &&
          scannedResults.repeatedRules.length > 0 ? (
            <>
              {scannedResults.repeatedRules
                .filter((rule) => rule.rule !== firstCalledRule?.rule) // Exclude first called rule
                .map((rule, index) => (
                  <p
                    key={index}
                    className={rule.count > 3 ? "highlighted" : ""}
                  >
                    <strong>Rule:</strong> {rule.rule}, <strong>Count:</strong>{" "}
                    {rule.count}, <strong>Total Duration:</strong>{" "}
                    {rule.totalDuration} ms
                  </p>
                ))}
            </>
          ) : (
            <p>No repeated rules found.</p>
          )}
        </div>
      </div>

      {scannedResults.baseTraceLogs && scannedResults.baseTraceLogs.length > 0 && (
        <div className="scan-results-section">
          <div className="scan-results-header">
            <h2>BaseTrace Errors</h2>
            <input
              type="checkbox"
              checked={isBaseTraceVisible}
              onChange={handleBaseTraceCheckboxChange}
              className="toggle-visibility-checkbox"
            />
          </div>
          <div
            className={`scan-results-content ${
              isBaseTraceVisible ? "visible" : "hidden"
            }`}
          >
             {scannedResults.baseTraceLogs.map((log, index) => (
              <div key={index} className="base-trace-log">
                <p>
                  <strong>Timestamp:</strong> {log.timestamp}
                </p>
                <p>
                  <strong>Description:</strong> {log.description}
                </p>
                <p className="log-details">
                  <strong>Details:</strong> {log.details}
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default ScanResults;
