// src/components/Login.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/Login.css"; // Import the CSS file
import bannerCarrot from "../assets/banner-carrot10.jpg";

const Login = () => {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  console.log(process.env.REACT_APP_BACKEND_URL); // debugging

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/api/auth/login`, {
        // Retrieve the backend URL from .env

        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id, password }),
      });

      const data = await response.json();

      if (response.ok) {
        // Save authentication data to localStorage if login is successful
        localStorage.setItem("authData", JSON.stringify({ id }));
        navigate("/");
      } else {
        setError(data.message || "Login failed");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={bannerCarrot} alt="Banner Carrot" className="banner-image" />
        <h1>Hello Carrot People</h1>
        <form onSubmit={handleLogin}>
          <input
            type="text"
            placeholder="ID"
            value={id}
            onChange={(e) => setId(e.target.value)}
            className="login-input"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="login-input"
          />
          <button type="submit" className="login-button">
            <strong>Login</strong>
          </button>
        </form>
        {error && <p className="error">{error}</p>}
      </div>
    </div>
  );
};

export default Login;
