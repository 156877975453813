import React from "react";

function FileUpload({ selectedFile, setSelectedFile, setScannedResults }) {
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setScannedResults(null);
  };

  return (
    <>
      <label htmlFor="fileInput" className="custom-file-upload">
        Choose File
      </label>
      <input
        type="file"
        id="fileInput"
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
      {!selectedFile && (
        <p className="file-name">
          Please make sure file have <b>.md or .log</b> format.
        </p>
      )}
      {selectedFile && (
        <p className="file-name">Your file name: {selectedFile.name}</p>
      )}
    </>
  );
}

export default FileUpload;
