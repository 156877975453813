import React from "react";

function ButtonGroup({
  selectedFile,
  setSelectedFile,
  scannedResults,
  setScannedResults,
  isLoading,
  setIsLoading,
}) {
  const handleScan = async () => {
    if (selectedFile) {
      setIsLoading(true);

      const formData = new FormData();
      formData.append("file", selectedFile);

      try {
        const response = await fetch(
          "/api/calculate-duration",
          {
            method: "POST",
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setScannedResults(data);
      } catch (error) {
        console.error("Failed to scan", error);
      } finally {
        setIsLoading(false);
      }
    } else {
      alert("Please select a file first!");
    }
  };

  const handleReset = () => {
    setSelectedFile(null);
    setScannedResults(null);
    document.getElementById("fileInput").value = null;
  };

  const handleDownload = () => {
    if (scannedResults) {
      const now = new Date();
      const year = now.getFullYear().toString().slice(2);
      const month = String(now.getMonth() + 1).padStart(2, "0");
      const day = String(now.getDate()).padStart(2, "0");
      const hours = String(now.getHours()).padStart(2, "0");
      const minutes = String(now.getMinutes()).padStart(2, "0");
      const formattedTime = `${year}${month}${day}_${hours}${minutes}`;

      const fileName = selectedFile
        ? selectedFile.name.split(".").slice(0, -1).join(".")
        : "file";
      const downloadFileName = `result_${fileName}_${formattedTime}.json`;

      const blob = new Blob([JSON.stringify(scannedResults, null, 2)], {
        type: "application/json",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = downloadFileName;
      a.click();
      URL.revokeObjectURL(url);
    } else {
      alert("There are no scan results to download.");
    }
  };

  return (
    <div className="button-group">
      <button
        onClick={handleScan}
        disabled={!selectedFile || isLoading}
        className="scan-button monospaced-button"
      >
        {isLoading ? "Scanning..." : "SCAN"}
      </button>
      <button
        onClick={handleReset}
        disabled={isLoading}
        className="reset-button monospaced-button"
      >
        {isLoading ? "Resetting..." : "RESET"}
      </button>
      {scannedResults && (
        <button
          onClick={handleDownload}
          disabled={isLoading}
          className="download-button monospaced-button"
        >
          DOWNLOAD
        </button>
      )}
    </div>
    
  );
}

export default ButtonGroup;
