// src/components/Home.jsx
import React, { useState } from "react";
import "../App.css";
import FileUpload from "../components/FileUpload";
import ScanResults from "../components/ScanResults";
import ButtonGroup from "../components/ButtonGroup";
import Modal from "react-modal";

function Home() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [scannedResults, setScannedResults] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Function to handle log out
  const handleLogout = () => {
    localStorage.removeItem("authData"); // Remove authentication data from localStorage
    window.location.href = "/login"; // Redirect the user to the login page
  };

  // Function to open the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to open the user manual page
  const userManual = () => {
    window.open("https://carrotsolutions.atlassian.net/wiki/spaces/CSK/overview", "_blank");
  };

  return (
    <div className="container">
      <div className="info-section">
        <h1>Welcome to Carrot Internal Tool</h1>
        <p>
          This tool allows you to upload and scan files for internal processing.
          Make sure to follow the{" "}
          <span className="guidelines-link" onClick={openModal}>
            guidelines
          </span>{" "}
          provided for a smooth experience.
        </p>
      </div>
      <h2 className="header">Upload and Scan File</h2>
      {/* <p className="sub-header">Carrot Internal Tool</p> */}
      <FileUpload
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        setScannedResults={setScannedResults}
      />
      <ButtonGroup
        selectedFile={selectedFile}
        setSelectedFile={setSelectedFile}
        scannedResults={scannedResults}
        setScannedResults={setScannedResults}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
      />
      {scannedResults && <ScanResults scannedResults={scannedResults} />}
      <button onClick={handleLogout} className="logout-button monospaced-button">Log out</button>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Guidelines Modal"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <h2>Guidelines</h2>
        <p>Here are the guidelines for using the Carrot Internal Tool:</p>
        <ul>
          <li>Ensure the file format is supported (<b>.md or .log</b> format).</li>
          <li>This tool can handle large file sizes, we recommend keeping it under 10MB.</li>
          <li>Follow the instructions provided in the           <span className="guidelines-link" onClick={userManual}>
            user manual
          </span>{" "}.</li>
        </ul>
        <button onClick={closeModal} className="close-button">Close</button>
      </Modal>
    </div>
  );
}

export default Home;
